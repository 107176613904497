"use strict";

var _core = require("@uppy/core");

var _companionClient = require("@uppy/companion-client");

var _providerViews = require("@uppy/provider-views");

var _preact = require("preact");

const packageJson = {
  "version": "2.0.7"
};

const locale = require("./locale.js");

class Facebook extends _core.UIPlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = this.opts.id || 'Facebook';

    _companionClient.Provider.initPlugin(this, opts);

    this.title = this.opts.title || 'Facebook';

    this.icon = () => (0, _preact.h)("svg", {
      "aria-hidden": "true",
      focusable: "false",
      width: "32",
      height: "32",
      viewBox: "0 0 32 32"
    }, (0, _preact.h)("g", {
      fill: "none",
      fillRule: "evenodd"
    }, (0, _preact.h)("rect", {
      className: "uppy-ProviderIconBg",
      width: "32",
      height: "32",
      rx: "16",
      fill: "#3C5A99"
    }), (0, _preact.h)("path", {
      d: "M17.842 26v-8.667h2.653l.398-3.377h-3.051v-2.157c0-.978.248-1.644 1.527-1.644H21V7.132A19.914 19.914 0 0 0 18.623 7c-2.352 0-3.963 1.574-3.963 4.465v2.49H12v3.378h2.66V26h3.182z",
      fill: "#FFF",
      fillRule: "nonzero"
    })));

    this.provider = new _companionClient.Provider(uppy, {
      companionUrl: this.opts.companionUrl,
      companionHeaders: this.opts.companionHeaders,
      companionKeysParams: this.opts.companionKeysParams,
      companionCookiesRule: this.opts.companionCookiesRule,
      provider: 'facebook',
      pluginId: this.id
    });
    this.defaultLocale = locale;
    this.i18nInit();
    this.title = this.i18n('pluginNameFacebook');
    this.onFirstRender = this.onFirstRender.bind(this);
    this.render = this.render.bind(this);
  }

  install() {
    this.view = new _providerViews.ProviderViews(this, {
      provider: this.provider
    });
    const {
      target
    } = this.opts;

    if (target) {
      this.mount(target, this);
    }
  }

  uninstall() {
    this.view.tearDown();
    this.unmount();
  }

  onFirstRender() {
    return Promise.all([this.provider.fetchPreAuthToken(), this.view.getFolder()]);
  }

  render(state) {
    const viewOptions = {};

    if (this.getPluginState().files.length && !this.getPluginState().folders.length) {
      viewOptions.viewType = 'grid';
      viewOptions.showFilter = false;
      viewOptions.showTitles = false;
    }

    return this.view.render(state, viewOptions);
  }

}

Facebook.VERSION = packageJson.version;
module.exports = Facebook;