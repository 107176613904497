"use strict";

var _preact = require("preact");

module.exports = props => {
  const {
    icon,
    hasAudio,
    i18n
  } = props;
  return (0, _preact.h)("div", {
    className: "uppy-Audio-permissons"
  }, (0, _preact.h)("div", {
    className: "uppy-Audio-permissonsIcon"
  }, icon()), (0, _preact.h)("h1", {
    className: "uppy-Audio-title"
  }, hasAudio ? i18n('allowAudioAccessTitle') : i18n('noAudioTitle')), (0, _preact.h)("p", null, hasAudio ? i18n('allowAudioAccessDescription') : i18n('noAudioDescription')));
};