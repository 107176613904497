"use strict";

var _preact = require("preact");

function DiscardButton(_ref) {
  let {
    onDiscard,
    i18n
  } = _ref;
  return (0, _preact.h)("button", {
    className: "uppy-u-reset uppy-c-btn uppy-Audio-button",
    type: "button",
    title: i18n('discardRecordedFile'),
    "aria-label": i18n('discardRecordedFile'),
    onClick: onDiscard,
    "data-uppy-super-focusable": true
  }, (0, _preact.h)("svg", {
    width: "13",
    height: "13",
    viewBox: "0 0 13 13",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-hidden": "true",
    className: "uppy-c-icon"
  }, (0, _preact.h)("g", {
    fill: "#FFF",
    fillRule: "evenodd"
  }, (0, _preact.h)("path", {
    d: "M.496 11.367L11.103.76l1.414 1.414L1.911 12.781z"
  }), (0, _preact.h)("path", {
    d: "M11.104 12.782L.497 2.175 1.911.76l10.607 10.606z"
  }))));
}

module.exports = DiscardButton;