"use strict";

var _preact = require("preact");

var _hooks = require("preact/hooks");

/* eslint-disable jsx-a11y/media-has-caption */
const RecordButton = require("./RecordButton.js");

const RecordingLength = require("./RecordingLength.js");

const AudioSourceSelect = require("./AudioSourceSelect.js");

const AudioOscilloscope = require("./audio-oscilloscope/index.js");

const SubmitButton = require("./SubmitButton.js");

const DiscardButton = require("./DiscardButton.js");

function RecordingScreen(props) {
  const {
    stream,
    recordedAudio,
    onStop,
    recording,
    supportsRecording,
    audioSources,
    showAudioSourceDropdown,
    onSubmit,
    i18n,
    onStartRecording,
    onStopRecording,
    onDiscardRecordedAudio,
    recordingLengthSeconds
  } = props;
  const canvasEl = (0, _hooks.useRef)(null);
  const oscilloscope = (0, _hooks.useRef)(null); // componentDidMount / componentDidUnmount

  (0, _hooks.useEffect)(() => {
    return () => {
      oscilloscope.current = null;
      onStop();
    };
  }, [onStop]); // componentDidUpdate

  (0, _hooks.useEffect)(() => {
    if (!recordedAudio) {
      oscilloscope.current = new AudioOscilloscope(canvasEl.current, {
        canvas: {
          width: 600,
          height: 600
        },
        canvasContext: {
          lineWidth: 2,
          fillStyle: 'rgb(0,0,0)',
          strokeStyle: 'green'
        }
      });
      oscilloscope.current.draw();

      if (stream) {
        const audioContext = new AudioContext();
        const source = audioContext.createMediaStreamSource(stream);
        oscilloscope.current.addSource(source);
      }
    }
  }, [recordedAudio, stream]);
  const hasRecordedAudio = recordedAudio != null;
  const shouldShowRecordButton = !hasRecordedAudio && supportsRecording;
  const shouldShowAudioSourceDropdown = showAudioSourceDropdown && !hasRecordedAudio && audioSources && audioSources.length > 1;
  return (0, _preact.h)("div", {
    className: "uppy-Audio-container"
  }, (0, _preact.h)("div", {
    className: "uppy-Audio-audioContainer"
  }, hasRecordedAudio ? (0, _preact.h)("audio", {
    className: "uppy-Audio-player",
    controls: true,
    src: recordedAudio
  }) : (0, _preact.h)("canvas", {
    ref: canvasEl,
    className: "uppy-Audio-canvas"
  })), (0, _preact.h)("div", {
    className: "uppy-Audio-footer"
  }, (0, _preact.h)("div", {
    className: "uppy-Audio-audioSourceContainer"
  }, shouldShowAudioSourceDropdown ? AudioSourceSelect(props) : null), (0, _preact.h)("div", {
    className: "uppy-Audio-buttonContainer"
  }, shouldShowRecordButton && (0, _preact.h)(RecordButton, {
    recording: recording,
    onStartRecording: onStartRecording,
    onStopRecording: onStopRecording,
    i18n: i18n
  }), hasRecordedAudio && (0, _preact.h)(SubmitButton, {
    onSubmit: onSubmit,
    i18n: i18n
  }), hasRecordedAudio && (0, _preact.h)(DiscardButton, {
    onDiscard: onDiscardRecordedAudio,
    i18n: i18n
  })), (0, _preact.h)("div", {
    className: "uppy-Audio-recordingLength"
  }, !hasRecordedAudio && (0, _preact.h)(RecordingLength, {
    recordingLengthSeconds: recordingLengthSeconds,
    i18n: i18n
  }))));
}

module.exports = RecordingScreen;